import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));

const LoadingPage: React.FC = () => {
  return (
    <Container className="loader-page" data-testid="study-tool-loader">
      <CircularProgress />
      <Typography mt={2}>Loading...</Typography>
    </Container>
  );
};

export default LoadingPage;
